import React, { useEffect } from "react";
import usePageTranslation from "../../../hooks/usePageTranslation";
import { useSelector } from "react-redux";
import { getTranslatedText, initializeModal } from "../../../util/util";
import EditTranslationIcon from "../../common/edit-translation-icon/EditTranslationIcon";
import TooltipComponent from "../../common/bootstrap-component/tooltip-component";
import { useGetPromptTypesMutation } from "../../../api-integration/secure/secure";

interface PromptSummaryModalProps {
    id: string;
    selectedPromptData: any;
}

const PromptSummaryModal: React.FC<PromptSummaryModalProps> = (props) => {
    const PageTranslation = usePageTranslation();
    const {TranslationEditFlag} = useSelector((state: any) => state.commonSlice);

    const [getPromptTypesAPI, { data: promptTypeDetails}] =
    useGetPromptTypesMutation();

    useEffect(() => {
        getPromptTypesAPI({promptTypeId: 0});
    }, []);

    useEffect(() => {
        // Call the initializeModal function on mount/update
        initializeModal(props?.id);
    
        return () => {
            // Call the initializeModal function on unmount/cleanup
            initializeModal(props?.id);
        };
    }, [props?.id]);

    return (
        <div className="modal fade modal-draggable" data-bs-backdrop="false" id={props?.id} tabIndex={-1} aria-labelledby={`${props?.id}Label`} aria-hidden="true">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={`${props?.id}Label`}>
                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('promptDetails.text',PageTranslation)}}/>
                            {TranslationEditFlag && <EditTranslationIcon translationKey='promptDetails.text' translationType='text'/>}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                    {props?.selectedPromptData && 
                    <>
                    <div className="row mb-1 g-0">
                        <div className="col-sm-3 mb-sm-2 mb-0">
                            <img 
                                className={"img-fluid h-5 " + (props?.selectedPromptData?.BLUEPROMPT === 1 ? "border border-primary rounded" : (props?.selectedPromptData?.OPENSOURCE === 1 ? "border bl_border-orange rounded" : "border border-dark-subtle rounded"))} 
                                src={props?.selectedPromptData?.promptImage} 
                                alt={getTranslatedText('imageAlt.text',PageTranslation)} 
                            />
                        </div>
                        <div className="col-sm-7">
                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('name.text',PageTranslation)}}/>:
                            {TranslationEditFlag && <EditTranslationIcon translationKey='name.text' translationType='text'/>}
                            <p>{props?.selectedPromptData?.PROMPTNAME || '-'}</p>
                        </div>
                    </div>
                    <div className="row g-0 mb-2">
                        <div className="col-sm-3">
                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('description.text',PageTranslation)}}/>:
                            {TranslationEditFlag && <EditTranslationIcon translationKey='description.text' translationType='text'/>}
                        </div>
                        <div className="col-sm-9">
                            <div className="overflow-auto maxH-5 text-break scrollbar">
                                {props?.selectedPromptData?.PROMPTDESCRIPTION || '-'}
                            </div>
                        </div>
                    </div>
                    <div className="row g-0 mb-2">
                        <div className="col-sm-3">
                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('author.text',PageTranslation)}}/>:
                            {TranslationEditFlag && <EditTranslationIcon translationKey='author.text' translationType='text'/>}
                        </div>
                        <div className="col-sm-9">
                            <div>{props?.selectedPromptData?.OWNERFIRSTNAME} {props?.selectedPromptData?.OWNERLASTNAME}</div>
                        </div>
                    </div>
                    <div className="row g-0 mb-2">
                        <div className="col-sm-3">
                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('libraryList.text',PageTranslation)}}/>:
                            {TranslationEditFlag && <EditTranslationIcon translationKey='libraryList.text' translationType='text'/>}
                        </div>
                        <div className="col-sm-9">
                            <div className="overflow-auto maxH-5 text-break scrollbar">
                                {props?.selectedPromptData?.promptLibrary?.length > 0 ? (
                                    <>
                                        {props?.selectedPromptData?.promptLibrary?.map((prompt: any, index: number) => (
                                            <span key={prompt?.LIBID}>
                                                {prompt?.LIBNAME}{index < props.selectedPromptData.promptLibrary.length - 1 && ', '}
                                            </span>
                                        ))}
                                    </>
                                ) : '-'}
                            </div>
                        </div>
                    </div>
                    <div className="row g-0 mb-2">
                        <div className="col-sm-3">
                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('promptType.text',PageTranslation)}}/>:
                            {TranslationEditFlag && <EditTranslationIcon translationKey='promptType.text' translationType='text'/>}
                        </div>
                        <div className="col-sm-9">
                            <div>
                                {props?.selectedPromptData?.PROMPTTYPEID
                                    ? promptTypeDetails && promptTypeDetails?.promptTypeData?.find((item: any) => item.PROMPTTYPELID === props.selectedPromptData.PROMPTTYPEID)?.PROMPTTYPENAME || '-' 
                                    : '-'
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row g-0 mb-2">
                        <div className="col-sm-3">
                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('category.text',PageTranslation)}}/>:
                            {TranslationEditFlag && <EditTranslationIcon translationKey='category.text' translationType='text'/>}
                        </div>
                        <div className="col-sm-9">
                            <div className="d-flex">
                            {
                                [
                                    { condition: props?.selectedPromptData?.AGENTREADY === 1, textKey: 'agentReady.text' },
                                    { condition: props?.selectedPromptData?.BLUEPROMPT === 1, textKey: 'bluePrompt.text' },
                                    { condition: props?.selectedPromptData?.OPENSOURCE === 1, textKey: 'openSource.text' },
                                ]
                                .filter(item => item.condition) // Filter out items where condition is falsy
                                .map((item, index, array) => (
                                    <React.Fragment key={index}>
                                        <span className="d-inline">
                                        <span dangerouslySetInnerHTML={{ __html: getTranslatedText(item.textKey, PageTranslation) }} />
                                        {TranslationEditFlag && <EditTranslationIcon translationKey={item.textKey} translationType="text" />}
                                        </span>
                                        {index < array.length - 1 && <span className="me-1">,</span>}
                                    </React.Fragment>
                                ))
                            }
                            {
                                // Render 'standard' if no conditions are met
                                !(
                                props?.selectedPromptData?.AGENTREADY === 1 ||
                                props?.selectedPromptData?.BLUEPROMPT === 1 ||
                                props?.selectedPromptData?.OPENSOURCE === 1
                                ) && (
                                <div>
                                    {getTranslatedText('standard.text', PageTranslation)}
                                    {TranslationEditFlag && <EditTranslationIcon translationKey="standard.text" translationType="text" />}
                                </div>
                                )
                            }
                            </div>
                        </div>
                    </div>
                </>
                }
                </div>
                <div className="modal-footer">
                    <TooltipComponent title={getTranslatedText('close.tooltip',PageTranslation)}>
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('close.text',PageTranslation)}}/>
                        </button>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                        <>
                            <EditTranslationIcon translationKey='close.text' translationType='text'/>
                            <EditTranslationIcon translationKey='close.tooltip' translationType='tooltip'/>
                        </>
                    )}
                </div>
                </div>
            </div>
        </div>
    )
}

export default PromptSummaryModal;