import React, { useEffect, useRef } from 'react';
import { Tooltip } from 'bootstrap';

interface TooltipProps {
  children: React.ReactNode;
  title: string;
  placement?: 'auto' | 'top' | 'bottom' | 'left' | 'right' | undefined;
  additionalClass?: string;
}

let currentOpenTooltip: Tooltip | null = null;  // Global tracker for the currently open tooltip


const TooltipComponent: React.FC<TooltipProps> = ({ children, title, placement, additionalClass }) => {
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<Tooltip | null>(null);

  useEffect(() => {
    const node = nodeRef.current;
    
    if (node) {
      // Destroy the existing tooltip if there is one
      if (tooltipRef.current) {
        tooltipRef.current.dispose();// Clean up any previous tooltip
      }

      const tooltip = new Tooltip(node, {
          title: title,
          trigger: 'hover',
          html: true,
          placement: placement ? placement : 'top',
          customClass: `bc-text-start-tooltip${additionalClass ? ' ' + additionalClass : ''}`,
          //customClass: additionalClass ? 'bc-text-start-tooltip bc-text-limit-3-tooltip' : 'bc-text-start-tooltip',
      });

      tooltipRef.current = tooltip;

      const showTooltip = (e: Event) => {
        // Close the currently open tooltip (if any) before showing the new one
        if (currentOpenTooltip && currentOpenTooltip !== tooltip) {
          currentOpenTooltip.hide();
          currentOpenTooltip = null;
        }
        tooltip.show();
        currentOpenTooltip = tooltip; // Track the open tooltip
      };

      const hideTooltip = () => {
        tooltip.hide();
        currentOpenTooltip = null;
      };

      node.addEventListener('mouseenter', showTooltip);
      node.addEventListener('mouseleave', hideTooltip);
      node.addEventListener('focus', hideTooltip);
      node.addEventListener('click', hideTooltip);

      return () => {
        if (node) {
          node.removeEventListener('mouseenter', showTooltip);
          node.removeEventListener('mouseleave', hideTooltip);
          node.removeEventListener('focus', hideTooltip);
          node.removeEventListener('click', hideTooltip);
        }

        //check for tooltipRef.current, we can add another condition to ensure the tooltip is still attached to the DOM and prevent the dispose() call if it's no longer needed.
        //Added nodeRef.current to ensure that the node itself exists and is still attached to the DOM before calling dispose()
        
        // if (tooltipRef.current && nodeRef.current) {
        //   tooltipRef.current.hide();   // Ensure hiding before disposal
        //   tooltipRef.current.dispose(); // Properly dispose of tooltip
        //   tooltipRef.current = null;    // Clear the reference
        // }
        if (tooltipRef.current && nodeRef.current) {
          try {
            tooltipRef.current.hide();   // Ensure hiding before disposal
            //tooltipRef.current.dispose(); // Properly dispose of tooltip
          } catch (error) {
            //console.error('Error disposing tooltip:', error);
          } finally {
            tooltipRef.current = null;    // Clear the reference
          }
        }
      };
    }
  }, [title, placement]);

  return <span ref={nodeRef}>{children}</span>;
};

export default TooltipComponent;