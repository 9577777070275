import React, { useEffect, useState } from "react";
import usePageTranslation from "../../../hooks/usePageTranslation";
import { useDispatch, useSelector } from "react-redux";
import { getTranslatedText, initializeModal, replaceMentions } from "../../../util/util";
import EditTranslationIcon from "../../common/edit-translation-icon/EditTranslationIcon";
import TooltipComponent from "../../common/bootstrap-component/tooltip-component";
import { useGetPromptFramingTagsMutation } from "../../../api-integration/secure/secure";
import { updateAlertMessage } from "../../../api-integration/commonSlice";

interface ViewInputPromptModalProps {
    id: string;
    inputPrompt: string;
}

const ViewInputPromptModal: React.FC<ViewInputPromptModalProps> = (props) => {
    const dispatch = useDispatch();
    const PageTranslation = usePageTranslation();
    const { TranslationEditFlag } = useSelector((state: any) => state.commonSlice);
    const [reverseMentionMap, setReverseMentionMap] = useState<Map<string, number>>(new Map());    

    const [getPromptFramingTagsAPI, {data: getPromptFramingTagsData, isSuccess: isGetPromptFramingTagsSuccess, isError: isGetPromptFramingTagsError, error: getPromptFramingTagsError}] 
    = useGetPromptFramingTagsMutation();

    useEffect(() => {
        // Call the initializeModal function on mount/update
        initializeModal(props?.id);

        return () => {
            // Call the initializeModal function on unmount/cleanup
            initializeModal(props?.id);
        }
    },[props?.id]);

    useEffect(() => {
        getPromptFramingTagsAPI({});
    },[]);

    useEffect(() => {
        if(isGetPromptFramingTagsSuccess) {
            if(getPromptFramingTagsData?.success === true) {
                const options = getPromptFramingTagsData?.promptFramingVariablesData?.map((prompt: any) => ({
                    'id': prompt?.PROMPTVARID,
                    'display': prompt?.PROMPTVARNAME
                }));
                const map = new Map<string, number>();
                options.forEach((item: any) => {
                    map.set(item.display, item.id); 
                });
                setReverseMentionMap(map);
            }
            else if(getPromptFramingTagsData?.success === false) {
                dispatch(updateAlertMessage({status: "error", message: getPromptFramingTagsData?.message}))
            }
        }
        if(isGetPromptFramingTagsError || getPromptFramingTagsError) {
            dispatch(updateAlertMessage({status: "error", message: getPromptFramingTagsData?.message}))
        }
    },[isGetPromptFramingTagsSuccess, isGetPromptFramingTagsError, getPromptFramingTagsError]);

    const handleCopyInputPrompt = () => {
        navigator.clipboard.writeText(props?.inputPrompt);
        dispatch(updateAlertMessage({ status: 'success', message: getTranslatedText('inputPromptCopy.message',PageTranslation) }));
    }

    return (
        <div className="modal fade modal-draggable" data-bs-backdrop="false" id={props?.id} tabIndex={-1} aria-labelledby={`${props?.id}Label`} aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={`${props?.id}Label`}>
                            <span dangerouslySetInnerHTML={{ __html: getTranslatedText('viewInputPrompt.text', PageTranslation) }} />
                            {TranslationEditFlag && <EditTranslationIcon translationKey="viewInputPrompt.text" translationType="text" />}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <span dangerouslySetInnerHTML={{__html: replaceMentions(props?.inputPrompt, reverseMentionMap)}}/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <TooltipComponent title={getTranslatedText('close.tooltip',PageTranslation)}>
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                            {getTranslatedText('close.text',PageTranslation)}
                        </button>
                        </TooltipComponent>
                        {TranslationEditFlag && (
                            <>
                                <EditTranslationIcon translationKey="close.text" translationType="text" />
                                <EditTranslationIcon translationKey="close.tooltip" translationType="tooltip" />
                            </>
                        )}
                        <TooltipComponent title={getTranslatedText('copy.tooltip',PageTranslation)}>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleCopyInputPrompt}>
                            {getTranslatedText('copy.text',PageTranslation)}
                        </button>
                        </TooltipComponent>
                        {TranslationEditFlag && (
                            <>
                                <EditTranslationIcon translationKey="copy.text" translationType="text" />
                                <EditTranslationIcon translationKey="copy.tooltip" translationType="tooltip" />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewInputPromptModal;