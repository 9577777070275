import Card from "../card/card";
import NoPicture from '../../../assets/images/nopicture.jpg';
import { useDispatch, useSelector } from "react-redux";
import { fullPageLoader, updateAlertMessage, updateTranslationEditFlag, updateUser } from "../../../api-integration/commonSlice";
import { useLogoutMutation, useGenerateReferralIdMutation } from "../../../api-integration/secure/secure";
import { useEffect, useState } from "react";
import { clearCookies, getTranslatedText } from "../../../util/util";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Copy from '../../../assets/icons/copy.svg';
import TooltipComponent from '../../../components/common/bootstrap-component/tooltip-component';
import usePageTranslation from "../../../hooks/usePageTranslation";
import EditTranslationIcon from "../edit-translation-icon/EditTranslationIcon";
import CreateRefKeyModal from "../../secure/modals/create-ref-key-modal";

interface IUserCardProps {
  loginUrl?: string; 
}
const UserCard= (props: IUserCardProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const PageTranslation = usePageTranslation();
  const generateReferralKeyModalId = "generateReferralKeyModal";
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
  const { user, TranslationEditFlag } = useSelector((state: any) => state.commonSlice);
  //const [translationEditFlag, settranslationEditFlag] = useState(false);
 
  const [localRefKey, setLocalRefKey] = useState('');
  const [localStorageUser, setLocalStorageUser] = useState(JSON.parse(localStorage.getItem('user') as string));
  
  const dispatch = useDispatch();
  const [logoutAPI, { data: loggoutOut, isLoading, isSuccess, isError, error: logoutError }] =
    useLogoutMutation();
  const [generateRefKeyAPI, { data: refKeyData, isLoading:isKeyLoading, isSuccess: isKeySuccess, isError: isKeyError, error: KeyError }] =
    useGenerateReferralIdMutation();
    

  const logout = () => {
    dispatch(fullPageLoader(true));
    logoutAPI({});
  };

  const login = () => {
    sessionStorage.setItem('page', location?.pathname);
    if (props.loginUrl && props.loginUrl !== '') {
      navigate(props.loginUrl);
    } 
    else {
      navigate('/login');
    }
  }
  function generateReferralPath(refKey: string): string {
    const { protocol, host } = window.location;
    return `${protocol}//${host}/referral/${refKey}`;
  }
  useEffect(() => {
    const userRefKey = user?.referralId; 
    const referralPath = generateReferralPath(userRefKey);
    setLocalRefKey(referralPath || '');
    setLocalStorageUser(JSON.parse(localStorage.getItem('user') as string));
  }, [user]);

  const contentCopyURL = () => {
    navigator.clipboard.writeText(localRefKey || '')
    dispatch(updateAlertMessage({ status: 'success', message: getTranslatedText('copiedToClipboard.success.message', PageTranslation)}));
  }
  const handleGenerateReferralKey = () => {
    dispatch(fullPageLoader(true));
    generateRefKeyAPI({});
  }
  
  useEffect(() => {
    if(isKeySuccess){
      dispatch(fullPageLoader(false));
      if(refKeyData?.success === true) {
        dispatch(updateAlertMessage({ status: 'success', message: refKeyData.message }));
        if (refKeyData?.refId) {
          let user = JSON.parse(localStorage.getItem('user') as string);
          user.referralId = refKeyData?.refId;
          user.showRefKey = false;
          dispatch(updateUser(user));
          localStorage.setItem('user', JSON.stringify(user));
        }
      }
      else {
        dispatch(updateAlertMessage({ status: 'error', message: refKeyData.message }));
      }
    }
    if(isKeyError || KeyError){
      dispatch(fullPageLoader(false));
      dispatch(updateAlertMessage({ status: 'error', message: refKeyData?.message}));
    }
  }, [isKeySuccess, isKeyError, KeyError]);

  useEffect(() => {
    if (loggoutOut) {
      dispatch(fullPageLoader(false));
      localStorage.clear();
      dispatch(updateAlertMessage({ status: 'success', message: getTranslatedText('logout.success.message', PageTranslation)}));
      clearCookies('accessToken')
      localStorage.removeItem('user');
      navigate('/login');
    }
    if (logoutError) {
      dispatch(updateAlertMessage({ status: 'error', message: getTranslatedText('logout.failure.message', PageTranslation)}));
      dispatch(fullPageLoader(false));
    }
  }, [loggoutOut, logoutError]);
  return (
    <>
    <Card logo={true} titleType={1} share={true} settings={true} settingsClicked={() => navigate(`/app/userProfileEdit`)} 
      homeDisabled={location?.pathname.includes('home')} 
      settingsDisabled={location?.pathname.includes('userProfileEdit')} 
      help={true} Feedback={true} notification={true}
      logout={isLoggedIn ? true : false} 
      home={true} onLogout={logout} 
      title='myProfile.card.title' id="user_profile" 
      helpTitle="myProfile.cardHelp.title"
      helpContent="myProfile.cardHelp.content" 
    >
      <div className="d-flex justify-content-between">
        <div>
          <h5 className="card-title">
            {isLoggedIn && user?.firstName && user?.lastName ?
                <>
                  <div className="cursor-pointer" onClick={() => navigate(`/app/userProfileEdit`)}>
                    {user?.firstName ? user?.firstName : ''}
                    <br />
                    {user?.lastName ? user?.lastName : ''}
                  </div>
                </>
                : 
                getTranslatedText('guestWelcome.text', PageTranslation)
            }
          </h5>
          {user?.blcFlag &&
            <>
              <div>Editing</div>
              <div className="mb-2 form-check form-switch">
                <input className="form-check-input cursor-pointer" 
                  type="checkbox" 
                  checked={TranslationEditFlag}
                  onChange={(e) => dispatch(updateTranslationEditFlag(e.target.checked))}
                />
              </div>
            </>
          }
        </div>
        <div>
          <img 
            src={isLoggedIn && user && user.picPath ? user?.picPath : NoPicture} 
            alt= {getTranslatedText('imageAlt.text', PageTranslation)}
            className="blc_image_lg cursor-pointer rounded rounded-circle img-fluid" 
            onClick={() => navigate(`/app/userProfileEdit`)} 
          />
        </div>
      </div>
      {
        (!isLoggedIn) && 
        <div className="d-inline-block">
          <TooltipComponent title={getTranslatedText('login.tooltip', PageTranslation)} >
            <button className="btn btn-primary btn-md rounded-pill px-3" onClick={login}>
              {getTranslatedText('login.text', PageTranslation)}
            </button>
          </TooltipComponent>
        </div>
      }
      {isLoggedIn && user?.usrCreatedDate &&
        <div className="card-text">
          {getTranslatedText('promptAuthor.text', PageTranslation)}
          {TranslationEditFlag && (
            <EditTranslationIcon translationKey="promptAuthor.text" />
          )}
          <br />
          <h3>
            <NavLink to={`/app/accounting`} className="text-decoration-none">
              <TooltipComponent title={getTranslatedText('ccTotal.tooltip', PageTranslation)} > 
                {getTranslatedText('cc.text', PageTranslation)} {user?.totalCCPoints ? new Intl.NumberFormat('en-US').format(user?.totalCCPoints) : ''}
              </TooltipComponent>
            </NavLink>
            {TranslationEditFlag && (
              <>
                <EditTranslationIcon translationKey="cc.text" />
                <EditTranslationIcon translationKey="ccTotal.tooltip" translationType="tooltip" />
              </>
            )}
          </h3>
          {user?.referralId ?
            <div>
                {getTranslatedText('refId.text', PageTranslation)}:
                {TranslationEditFlag && (
                  <EditTranslationIcon translationKey="refId.text" />
                )}
                <br />
                <div className="d-flex mb-2">
                  <TooltipComponent title={getTranslatedText('refId.tooltip', PageTranslation)} > 
                    <h4>{user?.referralId}</h4>
                  </TooltipComponent>
                  {TranslationEditFlag && (
                    <EditTranslationIcon translationKey="refId.tooltip" translationType="tooltip" />
                  )}
                  <TooltipComponent title={getTranslatedText('refKeyCopy.tooltip', PageTranslation)} >
                    <img src={Copy} alt= {getTranslatedText('imageAlt.text', PageTranslation)} className='ps-2 h-1-5 cursor-pointer align-baseline' onClick={contentCopyURL} /><br />
                  </TooltipComponent>
                  {TranslationEditFlag && (
                    <EditTranslationIcon translationKey="refKeyCopy.tooltip" translationType="tooltip" />
                  )}
                </div>
            </div>
          : 
            <div className="mb-2">
              <TooltipComponent title={getTranslatedText('refKeyGenerate.tooltip', PageTranslation)} >
                <button type="button" 
                  className="btn btn-primary btn-md rounded-pill px-3" 
                  data-bs-toggle="modal" 
                  data-bs-target={`#${generateReferralKeyModalId}`}
                >
                  {getTranslatedText('refKeyGenerate.text', PageTranslation)}
                </button>
              </TooltipComponent>
              {TranslationEditFlag && (
              <>
                <EditTranslationIcon translationKey="refKeyGenerate.text" />
                <EditTranslationIcon translationKey="refKeyGenerate.tooltip" translationType="tooltip" />
              </>
            )}
            </div>
          }
          {getTranslatedText('bluecallomerSince.text', PageTranslation)} {new Date(user?.usrCreatedDate).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}<br />
          {TranslationEditFlag && (
            <EditTranslationIcon translationKey="bluecallomerSince.text" />
          )}
        </div>
      }
    </Card>
    <CreateRefKeyModal 
      id={generateReferralKeyModalId} 
      title="refKeyHelp.modal.title" 
      content="refKeyHelp.modal.content"
      onCreateRefKey={handleGenerateReferralKey}
    />
  </>
  )
}

export default UserCard;