import React, { useEffect, useState } from 'react';
import UserCard from '../../components/common/user-card/user-card';
import AgentList from '../../components/common/agent-list';
import AIResponseGenerator from '../../components/common/aI-response-generator';
import { useGetAgentsMutation, useGetAgentToExecuteMutation } from '../../api-integration/secure/secure';
import { useDispatch, useSelector } from 'react-redux';
import { fullPageLoader, updateAlertMessage, updateUser } from '../../api-integration/commonSlice';
import AssetExecution from '../../components/common/asset-execution';
import { useLocation, useParams } from 'react-router-dom';
import PromptList from '../../components/common/prompt-list/prompt-list';
import { IAgentData, IPromptData } from '../../interfaces/common/assets.interface';
import { usePublicPromptDetailsMutation } from '../../api-integration/public/common';
import PromptExecution from '../../components/public/common/prompt-execution/prompt-execution';
import AiResponse from '../../components/secure/create-prompts/ai-response/ai-response';
import Statistics from '../../components/common/statistics/statistics';
import { usePublicAskGPTMutation, usePublicAskGPTRefineResultMutation } from '../../api-integration/secure/prompts';
import { getTranslatedText, ProcessingMessageKeyValues } from '../../util/util';
import usePageTranslation from '../../hooks/usePageTranslation';


const AssetIO: React.FC = () => {
    const dispatch = useDispatch();
    const params = useParams<{ id: string }>();
    const location = useLocation();
    const PageTranslation = usePageTranslation();
    const queryParams = new URLSearchParams(location.search);
    //Extract instance code in case of prompt IO (GPTBlue IO)
    const URLInstanceAttr = queryParams.get("instanceCode") || '';
    // Extract paramURLCode and paramAssetType from the id parameter
    let paramURLCode = params.id || '';
    let paramAssetType = queryParams.get("assetType") || '';

    // if (params.id) {
    //     if (params.id.includes('&')) {
    //         // Case 2: URL contains both paramURLCode and paramType
    //         const [urlCode, paramType] = params.id.split('&');
    //         paramURLCode = urlCode;
    //         paramAssetType = paramType.split('=')[1];
    //     }
    //     else if (params.id.includes('=')) {
    //         // Case 1: URL contains only paramAssetType
    //         paramAssetType = params.id.split('=')[1];
    //     } else {
    //         // Case 3: URL contains only paramURLCode
    //         paramURLCode = params.id;
    //     } 
    // }
    
    if (!paramAssetType && params.id) {
        if (params.id.includes('&')) {
            // Case: URL contains both paramURLCode and paramType
            const [urlCode, paramType] = params.id.split('&');
            paramURLCode = urlCode;
            if (paramType.startsWith('assetType=')) {
                paramAssetType = paramType.split('=')[1];
            }
        } else if (params.id.includes('=')) {
            // Case: URL contains only paramAssetType
            const [key, value] = params.id.split('=');
            if (key === 'assetType') {
                paramAssetType = value;
                paramURLCode = '';
            }
        }
    }
    //const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
    //CONSTS FOR AGENT EXECUTION (Agent IO)
    // const [agentsList, setAgents List] = useState([]);
    const [localAgentDetails, setLocalAgentDetails] = useState<IAgentData>();
    const [localPromptDetails, setLocalPromptDetails] = useState<IPromptData>();
    const [childExecutionFormData, setChildExecutionFormData] = useState<any>();
    const [agentApiErrorMsg, setAgentApiErrorMsg] = useState('');
    const [reloadAgentToExecute, setReloadAgentToExecute] = useState(false);
    const [childAIResponseLoading, setChildAIResponseLoading] = useState(false);
    const [childAgentCompletionMsg, setChildAgentCompletionMsg] = useState('');
    const [localLastExecutedPromptData, setLocalLastExecutedPromptData] = useState('');
    const [localAgentAllDetails, setLocalAgentAllDetails] = useState<any>();
    const [localInstanceCode, setLocalInstanceCode] = useState('');
    const [localInstanceStatus, setLocalInstanceStatus] = useState(0);
    const [localAgentInstanceId, setLocalAgentInstanceId] = useState<number>(0);
    const [childAgentTotalCCUsed, setChildAgentTotalCCUsed] = useState<number>(0);
    const [childAgentProductivity, setChildAgentProductivity] = useState<number>(0);
    const [isAgentToExecuteLoading, setIsAgentToExecuteLoading] = useState<boolean>(false);
    const [reloadAgentListAPI, setReloadAgentListAPI] = useState<boolean>(true);
    
    //CONSTS FOR PROMPT EXECUTION(GPTBlue IO)
    const { LibraryType } = useSelector((state: any) => state.commonSlice);
    const [reloadPromptList, setReloadPromptList] = useState(false);
    const [reloadStats,setReloadStats] = useState(false);
    const [widerLayout, setWiderLayout] = useState(true);
    const [isLibraryTypeChanged, setIsLibraryTypeChanged] = useState(false);
    const [URLInstanceCode, setURLInstanceCode] = useState('');
    const [showChildResponseSection, setShowChildResponseSection] = useState(false);
    const [showChildSurveySuccessMsg, setShowChildSurveySuccessMsg] = useState(false);
    const [isIterativeFinished, setIsIterativeFinished] = useState(false);
    const [userPromptAction, setUserPromptAction] = useState('');
    const [isSurveyPrompt, setIsSurveyPrompt] = useState(false);
    const [isIterativePrompt, setIsIterativePrompt] = useState<boolean>(false);
    const [accountTypeForCCDeduction, setAccountTypeForCCDeduction] = useState<string>(LibraryType === 'org' ? 'corp' : 'user');
    const [userFirstPromptId, setUserFirstPromptId] = useState<number>(0);
    const [aiResponse, setAiResponse] = useState<any>({});
    const [refineResponse, setRefineResponse] = useState<any>({});
    const [localpromptExeAlert, setLocalpromptExeAlert] = useState<number>(0);
    const [loginUrl, setLoginUrl] = useState<string>('');
    const [processingMsgKey, setProcessingMsgKey] = useState(ProcessingMessageKeyValues[0]);
    const [currentMsgIndex, setCurrentMsgIndex] = useState(0);
    let myInterval: any;
    //const [childRequestForResponse, setChildRequestForResponse] = useState<boolean>(false);

    //API Used For Agent Execution(Agent IO)
    const [getAgentToExecuteAPI, { data: agentToExecuteData,  isSuccess: isAgentToExecuteSuccess, isError: isAgentToExecuteError, error: agentToExecuteError }] 
    =useGetAgentToExecuteMutation();

    // const [getAgentsAPI, {data: AllAgentsList, isSuccess: isAgentsSuccess, isError: isAgentsError, error: agentsError}] 
    // = useGetAgentsMutation();

    //API Used For Prompt Execution(GPTBlue IO)
    const [publicPromptDetailsAPI, { data: publicPromptDetails, isLoading: isLoadingPublicPrompt, isSuccess: isSucccessPublicPrompt, isError: isErrorPublicPrompt, error: errorPublicPrompt }] =
    usePublicPromptDetailsMutation();
    const [publicAskGPTAPI, { data: publicAskGPTResponse, isLoading: isPublicAskGPTLoading, isSuccess: isPublicAskGPTSuccess, isError: isPublicAskGPTError, error: publicAskGPTError }] =
    usePublicAskGPTMutation();
    const [publicAskGPTRefineResultAPI, { data: publicAskGPTRefineResponse, isLoading: isPublicAskGPTRefineResultLoading, isSuccess: isPublicAskGPTRefineResultSuccess, isError: isPublicAskGPTRefineResultError, error: publicAskGPTRefineResultError }] =
    usePublicAskGPTRefineResultMutation();

    useEffect(() => {
        //Case of agent execution(Agent IO)
        if (paramAssetType === "agent") {
            if (paramURLCode) {
                setReloadAgentToExecute(false);
                // get the agent details which URLcode is passed in the URL
                getAgentToExecuteAPI({
                    "agentPublicCode": paramURLCode,
                    "instanceId": localAgentInstanceId
                });
                setReloadStats(!reloadStats); // Reload the statistics
            }
        
            // // Get the list of all agents
            // getAgentsAPI({
            //     AgentId: 0,
            //     fetchHistroyFlag: 0,
            //     requestMode: "executeAgent"
            // });
        }
        //Case of prompt execution(GPTBlue IO)
        else if (paramAssetType === "prompt" && paramURLCode) {
            dispatch(fullPageLoader(true));
            // get the prompt details which URLcode is passed in the URL
            publicPromptDetailsAPI({
                "promptPublicCode": paramURLCode,
                "instanceCode": URLInstanceCode || '',
            });
            setAiResponse('');
            setReloadStats(!reloadStats); // Reload the statistics
        }
    }, [paramURLCode, paramAssetType, reloadAgentToExecute, localAgentInstanceId, dispatch, URLInstanceCode]);

    //=======================================//
    //START: Logic for Agent Execution(Agent IO) 
    //=======================================// 
    const startDisplayingMessages = () => {
        // Display the first message immediately
        setProcessingMsgKey(ProcessingMessageKeyValues[0]);
        setCurrentMsgIndex(0);

        // Start the interval after 5 seconds
        const initialTimeout = setTimeout(() => {
            const myInterval = setInterval(() => {
            setCurrentMsgIndex((prevIndex) => {
                if (prevIndex < ProcessingMessageKeyValues.length - 1) {
                const nextIndex = prevIndex + 1;
                setProcessingMsgKey(ProcessingMessageKeyValues[nextIndex]);
                return nextIndex;
                } else {
                clearInterval(myInterval);
                return prevIndex;
                }
            });
            }, 5000);
        }, 5000);

        return () => {
            clearTimeout(initialTimeout);
            clearInterval(myInterval);
        };
    };  
    useEffect(() => {
        if(isAgentToExecuteSuccess){
            // dispatch(fullPageLoader(false));
            setAgentApiErrorMsg('');
            if(agentToExecuteData.success){
                //Send the instance code AI Response Generator component
                setLocalInstanceCode(agentToExecuteData?.assetData?.agentInstance?.INSTANCECODE || '');
                setLocalInstanceStatus(agentToExecuteData?.assetData?.agentInstance?.INSTANCESTATUS);
                //Send the agent details to the Asset Execution component
                setLocalAgentDetails(agentToExecuteData?.assetData?.agentDetails);
                setLocalPromptDetails(agentToExecuteData?.assetData?.promptToExecuteData?.promptDetail?.[0]);
                setLocalAgentAllDetails(agentToExecuteData?.assetData);                
                //Send last executed prompt details to the AI Response Generator component
                if (agentToExecuteData?.assetData?.agentInstance?.executedPromptData.length > 0) {
                    // Get the last item of the array
                    const lastItem = agentToExecuteData?.assetData?.agentInstance?.executedPromptData.slice(-1)[0];
                    setLocalLastExecutedPromptData(lastItem);
                }
                else {
                    setLocalLastExecutedPromptData('');
                }
            }
            else{
                setLocalLastExecutedPromptData('');
                setLocalInstanceCode('');
                setLocalAgentDetails(undefined);
                setLocalPromptDetails(undefined);
                // Set the error message for displaying in Execution component
                setAgentApiErrorMsg(agentToExecuteData?.message);
                dispatch(updateAlertMessage({ status: 'error', message: agentToExecuteData?.message }));
            }
        }
        if(isAgentToExecuteError || agentToExecuteError){
            // dispatch(fullPageLoader(false));
            setAgentApiErrorMsg('');
            dispatch(updateAlertMessage({ status: 'error', message: agentToExecuteData?.message }));
        }
    }, [isAgentToExecuteSuccess, isAgentToExecuteError, agentToExecuteError, agentToExecuteData]);

    // useEffect(() => {
    //     if(isAgentsSuccess){
    //         if(AllAgentsList?.success === true){
    //             setAgentsList(AllAgentsList.AgentDetail);
    //         }
    //         else{
    //             setAgentsList([]);
    //             dispatch(updateAlertMessage({ status: 'error', message: AllAgentsList?.message }));
    //         }
    //     }
    //     if(isAgentsError || agentsError){
    //         dispatch(updateAlertMessage({ status: 'error', message: AllAgentsList?.message }));
    //     }
    // }, [isAgentsSuccess, isAgentsError, agentsError, AllAgentsList]);
    //=======================================//
    //END: Logic for Agent Execution(Agent IO)
    //=======================================//

    //============================================//
    //START: Logic for Prompt Execution(GPTBlue IO)
    //============================================//
    useEffect(() => {
        if(URLInstanceAttr){
            setURLInstanceCode(URLInstanceAttr);
        }
    }, [URLInstanceAttr]);

    useEffect(() => {
        if(LibraryType === 'org'){
            setAccountTypeForCCDeduction('corp');
        }
        else{
            setAccountTypeForCCDeduction('user'); 
        }
    }, [LibraryType]);

    useEffect(() => {
        if(isSucccessPublicPrompt){
            dispatch(fullPageLoader(false));
            if(publicPromptDetails.success === true){
                setLocalPromptDetails(publicPromptDetails?.promptDetail?.[0]);
                setIsSurveyPrompt(publicPromptDetails?.promptDetail?.[0]?.SURVEYPROMPT === 1 ? true : false);
                setIsIterativePrompt(publicPromptDetails?.promptDetail?.[0]?.['ITERATIVETASK'] === 'yes' ? true : false);
                setLoginUrl(`/referral/${publicPromptDetails?.promptDetail?.[0]['OWNERREFERRALID']}` || '');
            }
            else{
                dispatch(updateAlertMessage({ status: 'error', message: publicPromptDetails?.message }));
            }
        }
        if(isErrorPublicPrompt || errorPublicPrompt){
            dispatch(fullPageLoader(false));
            dispatch(updateAlertMessage({ status: 'error', message: publicPromptDetails?.message }));
        }
    }, [isSucccessPublicPrompt, isErrorPublicPrompt, errorPublicPrompt, publicPromptDetails]);

    // Handle the response of the prompt execution on click of submit button
    useEffect(() => {
        if(isPublicAskGPTSuccess){
            if(publicAskGPTResponse) {
                dispatch(fullPageLoader(false));
                if (publicAskGPTResponse) {
                    if (publicAskGPTResponse?.success === false) {
                        if (publicAskGPTResponse?.status == 'FETCH_ERROR' || publicAskGPTResponse?.status == 'PARSING_ERROR') {
                            dispatch(updateAlertMessage({ status: 'error', message: getTranslatedText('serverAccess.failure.message',PageTranslation) }));
                        } else {
                            dispatch(updateAlertMessage({ status: 'error', message: publicAskGPTResponse?.message }));
                        }
                    } 
                    else {
                        setAiResponse(publicAskGPTResponse);
                        setUserFirstPromptId(publicAskGPTResponse?.userPromptId);
                        setReloadStats(true);
                        let user = JSON.parse(localStorage.getItem('user') as string);
                        if (user) {
                            user.promptExeAlert = localpromptExeAlert;
                            dispatch(updateUser(user));
                            localStorage.setItem('user', JSON.stringify(user));
                        }
                    }
                }
            } 
        }
        else if(isPublicAskGPTError || publicAskGPTError){
            dispatch(updateAlertMessage({ status: 'error', message: publicAskGPTResponse?.message }));
        }
    }, [publicAskGPTResponse, isPublicAskGPTSuccess, isPublicAskGPTError, publicAskGPTError]);

    // Handle the response of the refine result on click of refine button
    useEffect(() => {
        if(isPublicAskGPTRefineResultSuccess){
            if (publicAskGPTRefineResponse) {
                if (publicAskGPTRefineResponse?.success === false) {
                    if (publicAskGPTRefineResponse?.status === 'FETCH_ERROR' || publicAskGPTRefineResponse?.status === 'PARSING_ERROR') {
                        dispatch(updateAlertMessage({ status: 'error', message: getTranslatedText('serverAccess.failure.message',PageTranslation) }));
                    } else {
                        dispatch(updateAlertMessage({ status: 'error', message: publicAskGPTRefineResponse?.message }));
                    }
                } 
                else {
                    setRefineResponse(publicAskGPTRefineResponse);
                    setReloadStats(true);
                }
            }
        }
        else if(isPublicAskGPTRefineResultError || publicAskGPTRefineResultError){
            dispatch(updateAlertMessage({ status: 'error', message: publicAskGPTRefineResponse?.message }));
        }
    }, [publicAskGPTRefineResponse, isPublicAskGPTRefineResultSuccess, isPublicAskGPTRefineResultError, publicAskGPTRefineResultError]);

    const handleLibraryTypeChange = () => {
        setIsLibraryTypeChanged(false);
    };

    const handleWiderLayout = (data: boolean) => {
        setWiderLayout(data);
    };

    //In case of Prompt(GPTBlue IO) ,calls when click on submit to ask
    const handleSubmitToAsk = (data: any) => {
        setShowChildResponseSection(true);
        setIsIterativeFinished(false);
        setShowChildSurveySuccessMsg(false);
        setUserPromptAction('localTest');
        setAiResponse('');
        setRefineResponse('');
        setLocalpromptExeAlert(data?.promptExeAlert);
        publicAskGPTAPI({
          GPTBluePromptId: publicPromptDetails?.promptDetail?.[0]?.GPTBLUEPROMPTID || 0,
          accountType: accountTypeForCCDeduction, 
          page: "io",
          instanceCode: URLInstanceCode || '',
          ...data
        })
    };

    const handleRefineResult = (html: any) => {
        setShowChildResponseSection(true);
        setUserPromptAction('refineResult');
        setAiResponse('');
        setRefineResponse('');
        const payload = {
          "userPrompt": html,
          "GPTBluePromptId": publicPromptDetails?.promptDetail?.[0]?.GPTBLUEPROMPTID || 0,
          "userFirstPromptId": userFirstPromptId,
          "accountType": accountTypeForCCDeduction,
          "page": "io",
          "requestMode": "execute",
          "libraryType": "",
        }
        publicAskGPTRefineResultAPI(payload);
    }

    const handleReloadStats = ()=>{
        setReloadStats(!reloadStats);
    };
    //============================================//
    //END: Logic for Prompt Execution(GPTBlue IO)
    //============================================//

    return (
        <div className="container">
            <div className="row">
                <div className="bc-column-3 order-lg-1 order-4 d-flex flex-column">
                    <div className='order-lg-1 order-2'>
                        <UserCard 
                            loginUrl={(isSucccessPublicPrompt && publicPromptDetails) ? loginUrl : undefined}
                        />
                    </div>
                    <div className='my-3 order-lg-2 order-1'>
                        {paramAssetType === "agent" &&
                            <AgentList 
                                cardId="assetIo_agentList"
                                cardTitle="assetIoAgentList.card.title"
                                cardHelpTitle="assetIoAgentList.cardHelp.title"
                                cardHelpContent="assetIoAgentList.cardHelp.content"
                                page='agentIo'
                                reloadAgentListAPI = {reloadAgentListAPI}
                                setReloadAgentListAPI = {setReloadAgentListAPI}
                                setChildAgentCompletionMsg = {setChildAgentCompletionMsg}
                                setLocalAgentInstanceId={setLocalAgentInstanceId}
                            />
                        }
                        {paramAssetType === "prompt" &&
                            <PromptList
                                cardId="assetIo_promptList"
                                page='promptIo'
                                askGptResponse={aiResponse} 
                                loading={isPublicAskGPTLoading || isPublicAskGPTRefineResultLoading} 
                                setIsLibraryTypeChanged={setIsLibraryTypeChanged} 
                                onLibraryTypeChange={handleLibraryTypeChange} 
                                reloadPromptList={reloadPromptList}
                                setReloadPromptList={setReloadPromptList}
                            />
                        }
                    </div>
                </div>
                <div className={`bc-column-${widerLayout ? '9' : '6'} order-lg-2 order-2`}>
                    <div className="mb-3">
                        {paramAssetType === "agent" &&
                            <AssetExecution 
                                cardId="assetIo_execution"
                                cardTitle="assetIoExecution.card.title"
                                cardHelpTitle="assetIoExecution.cardHelp.title"
                                cardHelpContent="assetIoExecution.cardHelp.content"
                                paramAssetType={paramAssetType}
                                aiResponseloading={childAIResponseLoading}
                                childAgentTotalCCUsed={childAgentTotalCCUsed}
                                childAgentProductivity={childAgentProductivity}
                                setExecutionFormData= {setChildExecutionFormData}
                                agentApiErrorMsg={agentApiErrorMsg}
                                agentCompletionMsg={childAgentCompletionMsg}
                                agentAllDetails = {localAgentAllDetails}
                                isAgentToExecuteLoading={isAgentToExecuteLoading}
                                setReloadAgentToExecute = {setReloadAgentToExecute}
                                setLocalAgentInstanceId = {setLocalAgentInstanceId}
                                processingMsgKey={processingMsgKey || ""} 
                                startDisplayingMessages={startDisplayingMessages}
                                //setRequestForResponse={setChildRequestForResponse}
                            />
                        }
                        {paramAssetType === "prompt" &&
                            <PromptExecution 
                                cardId="assetIo_promptExecution"
                                cardTitle="assetIoPromptExecution.card.title"
                                cardHelpTitle="assetIoPromptExecution.cardHelp.title"
                                cardHelpContent="assetIoPromptExecution.cardHelp.content"
                                prompt={localPromptDetails}
                                askGptResponse={aiResponse}
                                TriggerAskGpt={handleSubmitToAsk}
                                loading={isPublicAskGPTLoading || isPublicAskGPTRefineResultLoading}
                                refineLoading={isPublicAskGPTRefineResultLoading} 
                                isLibraryTypeChanged={isLibraryTypeChanged}
                                setWiderLayout={handleWiderLayout}
                                setReloadPromptList = {setReloadPromptList}
                            />
                        }
                    </div>
                    <div>
                        {paramAssetType === "agent" &&
                            <AIResponseGenerator 
                                cardId="assetIo_aiResponse"
                                cardTitle="assetIoAiResponse.card.title"
                                cardHelpTitle="assetIoAiResponse.cardHelp.title"
                                cardHelpContent="assetIoAiResponse.cardHelp.content"
                                paramAssetType={paramAssetType}
                                selectedAgentDetails={localAgentDetails}
                                selectedPromptDetails={localPromptDetails}
                                dataToExecute={childExecutionFormData}
                                triggeredAskButton={childExecutionFormData ? true : false}
                                setReloadAgentToExecute={setReloadAgentToExecute}
                                setAIResponseLoading={setChildAIResponseLoading}
                                setAgentCompletionMsg={setChildAgentCompletionMsg}
                                lastExecutedPromptData={localLastExecutedPromptData}
                                instanceCode={localInstanceCode}
                                instanceStatus={localInstanceStatus}
                                setLocalAgentInstanceId={setLocalAgentInstanceId}
                                processingMsgKey={processingMsgKey || ""} 
                                startDisplayingMessages={startDisplayingMessages}
                                setChildAgentTotalCCUsed={setChildAgentTotalCCUsed}
                                setChildAgentProductivity={setChildAgentProductivity}
                                setIsAgentToExecuteLoading={setIsAgentToExecuteLoading}
                            />
                        }
                        {paramAssetType === "prompt" && (Object.keys(aiResponse).length > 0 || Object.keys(refineResponse).length > 0)  &&
                            <AiResponse
                                cardId="assetIo_promptResult"
                                cardTitle="assetIoPromptResult.card.title"
                                cardHelpTitle="assetIoPromptResult.cardHelp.title"
                                cardHelpContent="assetIoPromptResult.cardHelp.content"
                                onRatingPrompt={handleReloadStats} 
                                TriggerAskGpt={handleSubmitToAsk} 
                                prompt={publicPromptDetails?.promptDetail?.[0]} 
                                page="io" 
                                loading={isPublicAskGPTLoading} 
                                refineLoading={isPublicAskGPTRefineResultLoading} 
                                askGPTResponse={aiResponse} 
                                refineResults={refineResponse} 
                                isAskGPTError={isPublicAskGPTError} 
                                isRefineError={isPublicAskGPTRefineResultError} 
                                isIterative={isIterativePrompt} 
                                refineResult={handleRefineResult} 
                                isLibraryTypeChanged={isLibraryTypeChanged}
                                userPromptAction={userPromptAction}
                                setUserPromptAction={setUserPromptAction} 
                                isSurveyPrompt={isSurveyPrompt}
                                showResponseSection={showChildResponseSection}
                                setShowResponseSection={setShowChildResponseSection}
                                showSurveySuccessMsg ={showChildSurveySuccessMsg}
                                setShowSurveySuccessMsg={setShowChildSurveySuccessMsg}
                                isIterativeFinished={isIterativeFinished}
                                setIsIterativeFinished={setIsIterativeFinished} 
                            />
                        }
                    </div>
                </div>
                {!widerLayout && paramAssetType === "prompt" && 
                    <div className="bc-column-3 order-lg-3 order-5">
                        <Statistics 
                            //cardHeightClass="h-100" 
                            id="assetIo_analytics" 
                            statsType="prompt" 
                            objId= {localPromptDetails?.GPTBLUEPROMPTID || ''}
                            reloadCheck = {reloadStats} 
                            handleReloadCheck={handleReloadStats} 
                        />
                    </div>
                }
            </div>
        </div>
    );
};

export default AssetIO;