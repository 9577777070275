import React, { useEffect, useState } from 'react';
import Card from '../common/card/card';
import { convertMarkdownToHtml, getTranslatedText, hideModal } from '../../util/util';
import usePageTranslation from '../../hooks/usePageTranslation';
import { useDispatch, useSelector } from 'react-redux';
import AIResponseActionIcons from './ai-response-action-icons';
import {usePublicAskGPTRefineResultMutation } from '../../api-integration/secure/prompts';
import Editor, { BtnBold, BtnItalic, EditorProvider, Toolbar } from 'react-simple-wysiwyg';
import { IAgentData, IExtendedPromptData, IPromptData } from '../../interfaces/common/assets.interface';
import { useExecuteAgentMutation, useSubmitSurveyResponseMutation, useUpdateExecutedPromptInstanceMutation } from '../../api-integration/secure/secure';
import TooltipComponent from './bootstrap-component/tooltip-component';
import { fullPageLoader, updateAlertMessage } from '../../api-integration/commonSlice';
import EditTranslationIcon from './edit-translation-icon/EditTranslationIcon';
import SocialShareIcons from './modal/social-share-icons';
import SendCommentModal from './modal/send-comment';
import StarCommentModal from '../secure/modals/star-comment-modal';
import { useCommentPromptMutation, useRatePromptMutation } from '../../api-integration/public/common';
import SocialSharePost from './modal/SocialSharePost';


interface AIResponseGeneratorProps {
    cardId: string;
    cardTitle: string;
    cardHelpTitle: string;
    cardHelpContent: string;
    paramAssetType?: string; 
    dataToExecute?: IExtendedPromptData | any; 
    selectedPromptDetails?: IPromptData; 
    selectedAgentDetails?: IAgentData; 
    triggeredAskButton?: boolean; 
    lastExecutedPromptData?: any;
    instanceCode?: string; 
    instanceStatus?: number;
    processingMsgKey?: string;
    startDisplayingMessages?: () => void;
    setReloadAgentToExecute?: (reload: boolean) => void;
    setAIResponseLoading?: (loading: boolean) => void;
    setAgentCompletionMsg?: (msg: string) => void;
    setLocalAgentInstanceId?: (instanceId: number) => void;
    setChildAgentTotalCCUsed?: (cc: number) => void;
    setChildAgentProductivity?: (productivity: number) => void;
    setIsAgentToExecuteLoading: (loading: boolean) => void;
}

const AIResponseGenerator: React.FC<AIResponseGeneratorProps> = (props) => {
    const PageTranslation = usePageTranslation();
    const dispatch = useDispatch();
    const { protocol, host , origin, pathname, search, hash } = window.location;
    const fullURL = origin + pathname + search + hash;      
    const socialSharePostModalId = `${props?.cardId}_socialSharePostModal`;
    const { TranslationEditFlag, LibraryType } = useSelector((state: any) => state.commonSlice);
    const MAX_RATING = 7;
    //const [localtotalUsedCC, setLocaltotalUsedCC] = useState(0);
    const [localProcessingMsgKey, setLocalProcessingMsgKey] = useState("");
    const [localUserFirstPromptId, setLocalUserFirstPromptId] = useState("0");
    const [localQuestionToAsk, setLocalQuestionToAsk] = useState('');
    const [localAIResponse, setLocalAIResponse] = useState('');
    const [isCompleteExecutionProcess, setIsCompleteExecutionProcess] = useState(false);
    const [assetId, setAssetId] = useState(0);
    const [assetName, setAssetName] = useState('');
    const [assetURLCODE, setAssetURLCODE] = useState('');
    const [isShareIconVisible, setIsShareIconVisible] = useState(false);
    const [assetAuthorName, setAssetAuthorName] = useState('');
    const [localParamAssetType, setLocalParamAssetType] = useState('');
    const [showAiResponseEditible, setShowAiResponseEditible] = useState(false);
    const [localExecutedPromptExecutionId, setLocalExecutedPromptExecutionId] = useState(0);
    const [localExecutedPromptId, setLocalExecutedPromptId] = useState('');
    const [localLastExecutedUserPromptId, setLocalLastExecutedUserPromptId] = useState('0');
    const [localLastExecutedResponseMessage, setLocalLastExecutedResponseMessage] = useState('');
    const [showAIResponseFlag, setShowAIResponseFlag] = useState(true);
    const [userComment, setUserComment] = useState('');
    const [commentError, setCommentError] = useState("");
    //State for default rating
    const [ratings, setRatings] = useState<number>(0);
    // This state will be used to send the temporary stars to the modal when they are clicked.
    const [tempRating, setTempRating] = useState<number>(0); 

    const [socialShareName, setSocialShareName] = useState('');
    const [socialShareContent, setSocialShareContent] = useState('');

    // Used for Refine Result of prompt of the agent
    const [PublicAskGPTRefineResultAPI, { data: publicRefineResponseData, isLoading: isPublicRefineResponseLoading, isSuccess: isPublicRefineResponseSuccess }] =
    usePublicAskGPTRefineResultMutation();

    // Used for executing the agent
    const [executeAgentAPI, { data: executeAgentData, isLoading: isExecuteAgentLoading, isSuccess: isExecuteAgentSuccess, isError: isExecuteAgentError, error: executeAgentError }] =
    useExecuteAgentMutation();

    // Used for updating the executed prompt instance
    const [updateExecutedPromptInstanceAPI, { data: updateExecutedPromptInstanceData, isSuccess: isUpdateExecutedPromptInstanceSuccess, isError: isUpdateExecutedPromptInstanceError, error: updateExecutedPromptInstanceError }] =
    useUpdateExecutedPromptInstanceMutation();

    // Used for submitting the survey response
    const [submitSurveyResponseAPI, { data: surveyResponseSubmittedData, isLoading: isSurveyLoading, isSuccess: isSurveySuccess, isError: isSurveyError, error: surveyError }] =
    useSubmitSurveyResponseMutation();

    // Used for commenting on the prompt of the agent
    const [commentPromptAPI, { data: commentPromptData, isSuccess: isCommentSuccess, isError: isCommentError, error: commentPromptError }] =
    useCommentPromptMutation();

    // Used for rating the prompt of the agent
    const [RateGPTResponseAPI, { data: rateResponse, isSuccess: isRateSuccess, isError: isRateError, error: rateError }] = 
    useRatePromptMutation();

    useEffect(() => {
        setLocalProcessingMsgKey(props?.processingMsgKey || "");
    }, [props?.processingMsgKey]);

    //On page load
    useEffect(() => {
        //Check if the prompt is shareable or not
        setIsShareIconVisible(props?.selectedPromptDetails?.ALLOWSOCIALSHARE === 1 ? true : false);
        setShowAIResponseFlag(true);
        if(props?.paramAssetType === "agent"){
            setLocalParamAssetType("agent");
            setAssetId(props?.selectedAgentDetails?.AGENTID || 0);
            setAssetName(props?.selectedAgentDetails?.AGENTNAME || '');
            setAssetAuthorName(props?.selectedAgentDetails?.AGENTOWNERFIRSTNAME + ' ' + props?.selectedAgentDetails?.AGENTOWNERLASTNAME || '');
            setAssetURLCODE(props?.selectedAgentDetails?.AGENTURLCODE || '');
            
            //START SECTION: Check if the last executed prompt data is available
            // Below code will be executed when the user has executed a prompt in agent and agent execution is not yet completed
            // e.g. user reload the page after executing the prompt OR move to another page and come back to the agent page
            if(props?.lastExecutedPromptData ){ 
                setLocalExecutedPromptExecutionId(props?.lastExecutedPromptData?.PROMPTEXECUTIONID);
                setLocalLastExecutedUserPromptId(props?.lastExecutedPromptData?.USERPROMPTID);
                setLocalLastExecutedResponseMessage(props?.lastExecutedPromptData?.MESSAGE);
                setLocalExecutedPromptId(props?.lastExecutedPromptData?.PROMPTID);
                setLocalQuestionToAsk(props?.lastExecutedPromptData?.INPUTCONTENT || '');
                //setLocalAIResponse(convertMarkdownToHtml(props?.lastExecutedPromptData?.AIADVICE, 'stdText'));
                if(props?.lastExecutedPromptData?.PROMPTEXECUTIONSTATUS === 1 ){ 
                    //In case of iterative Or Survey prompt (when user did not finish PROMPTEXECUTIONSTATUS = 1), Set the last executed prompt response to show the AI response
                    //setLocalAIResponse(convertMarkdownToHtml(props?.lastExecutedPromptData?.AIADVICE, 'stdText'));
                }
                else{
                    // prompt execution is complete (PROMPTEXECUTIONSTATUS = 2) >> Set the AI response to empty
                    //if last prompt of agent then dont cleanup the response
                    if(props?.instanceStatus === 2){
                        setIsCompleteExecutionProcess(true);
                        setLocalAIResponse(convertMarkdownToHtml(props?.lastExecutedPromptData?.AIADVICE, 'stdText'));
                    }
                    else{
                        //Dont cleanup the response in every case
                        setLocalAIResponse(convertMarkdownToHtml(props?.lastExecutedPromptData?.AIADVICE, 'stdText'));
                        setLocalUserFirstPromptId(props?.lastExecutedPromptData?.USERPROMPTID);
                        //setLocalAIResponse('');
                    }
                }
            }
            else {
                setLocalAIResponse('');
            }
            //END SECTION: Check if the last executed prompt data is available
        }
    }, [props?.paramAssetType, props?.selectedAgentDetails, props?.selectedPromptDetails, props?.lastExecutedPromptData]);

    useEffect(() => {
        if (props?.triggeredAskButton) {
            if (props?.startDisplayingMessages) {
                props.startDisplayingMessages();
            }            
            setLocalAIResponse('');
            setLocalQuestionToAsk(props?.dataToExecute?.userPrompt || '');
            if(props?.paramAssetType === "agent"){
                const existingData = props?.dataToExecute || {};
                const newPayload = {
                    ...existingData,
                    agentid: props?.selectedAgentDetails?.AGENTID,
                    accountType: "corp"
                };
                executeAgentAPI(newPayload);
                props?.setIsAgentToExecuteLoading(true);
            }
        }
    }, [props?.triggeredAskButton, props?.dataToExecute, props?.paramAssetType]);

    useEffect(() => {
        if(isExecuteAgentLoading){
            props?.setAIResponseLoading && props.setAIResponseLoading(true);
        }
        else{
            props?.setAIResponseLoading && props.setAIResponseLoading(false);
        }
        if (isExecuteAgentSuccess) {
                props?.setIsAgentToExecuteLoading(false);
            if(executeAgentData.success === true){ 
                if(executeAgentData?.agentInstance?.INSTANCEID) {
                    props.setLocalAgentInstanceId && props.setLocalAgentInstanceId(executeAgentData?.agentInstance?.INSTANCEID);
                }
                // Check if the agent is completed
                if(executeAgentData?.agentCompletionStatus === 2){
                    setLocalAIResponse(convertMarkdownToHtml(executeAgentData?.aiAdvice, 'stdText'));
                    setLocalUserFirstPromptId(executeAgentData?.userPromptId);
                    props?.setAgentCompletionMsg && props?.setAgentCompletionMsg(executeAgentData?.message);
                    setIsCompleteExecutionProcess(true);
                    props?.setChildAgentTotalCCUsed && props?.setChildAgentTotalCCUsed(executeAgentData?.TotalCCUsed);
                    props?.setChildAgentProductivity && props?.setChildAgentProductivity(executeAgentData?.Productivity);
                    if(executeAgentData?.completedPromptData){
                        setIsShareIconVisible(executeAgentData?.completedPromptData?.promptDetail?.[0]?.ALLOWSOCIALSHARE === 1 ? true : false);
                    }
                    else{
                        setIsShareIconVisible(false);
                    }
                }
                else{
                    // Clear the agent completion message
                    props?.setAgentCompletionMsg && props?.setAgentCompletionMsg("");
                    
                    // Check if the executedPromptData is not empty
                    if (executeAgentData?.assetData?.agentInstance?.executedPromptData.length > 0) {
                        // Get the last item of the array
                        const lastItem = executeAgentData?.assetData?.agentInstance?.executedPromptData.slice(-1)[0];
                        // Access the AIADVICE field
                        const lastAIAdvice = lastItem.AIADVICE;
                        const lastUserPromptId = lastItem.USERPROMPTID;
                        const promptExecutionStatus = lastItem.PROMPTEXECUTIONSTATUS;
                        const lastExecutionMsg = lastItem.MESSAGE;
                        setLocalUserFirstPromptId(lastUserPromptId);
                        setLocalLastExecutedUserPromptId(lastUserPromptId);
                        setLocalExecutedPromptExecutionId(lastItem.PROMPTEXECUTIONID);
                        setLocalExecutedPromptId(lastItem.PROMPTID);
                        // Set the last executed response message to the local state
                        setLocalLastExecutedResponseMessage(lastExecutionMsg);
                        // Check if last promptExecutionStatus is 2 i.e. the prompt execution is completed
                        if(promptExecutionStatus === 2){
                            // Reload the agent to execute API to fetch the latest data
                            props.setReloadAgentToExecute && props.setReloadAgentToExecute(true);
                            setLocalAIResponse("");
                        }
                        else{
                            setLocalAIResponse(convertMarkdownToHtml(lastAIAdvice, 'stdText'));
                        }
                        //Check if prompt is not iterative and not a survey prompt then set the isCompleteExecutionProcess to true
                        setPromptLocalExecutionStatus(lastItem?.ITERATIVETASK, lastItem?.SURVEYPROMPT);
                    }
                }
            }
            else{
                dispatch(updateAlertMessage({ status: 'error', message: executeAgentData?.message }))
            }
        }
        if (isExecuteAgentError || executeAgentError) {
            dispatch(updateAlertMessage({ status: 'error', message: executeAgentData?.message }))
        }
    }, [isExecuteAgentSuccess, executeAgentData, isExecuteAgentError, executeAgentError, isExecuteAgentLoading]);

    const setPromptLocalExecutionStatus = (iterativetask: string, surveyPrompt: number) => {
        //Check if prompt is not iterative and not a survey prompt then set the isCompleteExecutionProcess to true
        if(
            (iterativetask === "no" || iterativetask === "")
            && surveyPrompt === 0){
            setIsCompleteExecutionProcess(true);
        }else{
            //set the isCompleteExecutionProcess to false when prompt is iterative or survey prompt
            //Prompt will be marked completed when user click on finish button in response tile
            setIsCompleteExecutionProcess(false);
        }
    };

    useEffect(() => {
        if(isSurveySuccess){
            setShowAIResponseFlag(false);
            setIsCompleteExecutionProcess(true); //user submitted the survey response from response tile
            if(props?.paramAssetType === "agent"){
                dispatch(fullPageLoader(true));
                updateExecutedPromptInstanceAPI({
                    "GPTBluePromptId": localExecutedPromptId, 
                    "promptExecutionId": localExecutedPromptExecutionId, 
                    "promptExecutionStatus": 2,
                    "inputContent": localQuestionToAsk,
                    "aiAdvice": localAIResponse,
                    "userPromptId": localLastExecutedUserPromptId, 
                    "responseMessage": localLastExecutedResponseMessage
                });
            }
        }
        else if(isSurveyError || surveyError){
            setShowAIResponseFlag(false);
        }
    }, [isSurveySuccess, isSurveyError, surveyError]);

    const onEditorChange = (e: any) => {
        setLocalAIResponse(e.target.value);
        setLocalQuestionToAsk(e.target.value);
    };

    const sendComment = () => {
        if (userComment.length > 0) {
            setCommentError("");
            dispatch(fullPageLoader(true));
            commentPromptAPI({
                "promptResponseId": localUserFirstPromptId,
                "userComment": userComment
            })
            .then(() => {
                // This function hides the modal.
                hideModal("sendCommentModalId");
                setUserComment("");
            });
        } else {
            setCommentError('comment.required.message');
        }
    };
    const rateGptBlue = async (rateVal: number, comment: string) => {
        dispatch(fullPageLoader(true));
        RateGPTResponseAPI({ 
            userPromptId: localUserFirstPromptId, 
            userRating: rateVal,
            userComment: comment
        });
    };

    const handleSetRating = (rating: number, comment: string) => {
        setRatings(rating);
        rateGptBlue(rating-1,comment);
    };

    const OnTriggedRefineButton = () => {
        if(localAIResponse !== ''){
            if (props?.startDisplayingMessages) {
                props.startDisplayingMessages();
            }
            setLocalAIResponse('');
            setLocalQuestionToAsk(localAIResponse);
            //setLocaltotalUsedCC(0);
            PublicAskGPTRefineResultAPI({
                "userPrompt": localAIResponse,
                "GPTBluePromptId": props?.selectedPromptDetails?.GPTBLUEPROMPTID,
                "userFirstPromptId": localUserFirstPromptId,
                "accountType": props?.paramAssetType === "agent" ? "corp" : LibraryType === 'org' ? 'corp' : 'user',
                "requestMode": "execute",
                "instanceCode": props?.instanceCode || ""
            });
        
        }
    };

    useEffect(() => {
        if (isPublicRefineResponseSuccess) {
            if(publicRefineResponseData.success === true){
                setLocalLastExecutedUserPromptId(publicRefineResponseData?.userPromptId);
                setLocalLastExecutedResponseMessage(publicRefineResponseData?.message);
                setLocalAIResponse(convertMarkdownToHtml(publicRefineResponseData?.aiAdvice, 'stdText'));
            }
            else{
                dispatch(updateAlertMessage({ status: 'error', message: publicRefineResponseData?.message }))
            }
        }
    }, [isPublicRefineResponseSuccess, publicRefineResponseData]);

    useEffect(() => {
        if
        (
            (
                props?.selectedPromptDetails?.SURVEYPROMPT === 0
                &&
                props?.selectedPromptDetails?.ITERATIVETASK === "no"
            )
            || isCompleteExecutionProcess
        )
        {
            setShowAiResponseEditible(false);
        }
        else{
            setShowAiResponseEditible(true);
        }
    }, [isCompleteExecutionProcess, props?.selectedPromptDetails?.SURVEYPROMPT, props?.selectedPromptDetails?.ITERATIVETASK]);

    const OnTriggerFinishButton = () => {
        setIsCompleteExecutionProcess(true);//user clicked on finish button
        if(props?.paramAssetType === "agent"){
            dispatch(fullPageLoader(true));
            updateExecutedPromptInstanceAPI({
                "GPTBluePromptId": localExecutedPromptId, 
                "promptExecutionId": localExecutedPromptExecutionId, 
                "promptExecutionStatus": 2,
                "inputContent": localQuestionToAsk,
                "aiAdvice": localAIResponse,
                "userPromptId": localLastExecutedUserPromptId, 
                "responseMessage": localLastExecutedResponseMessage
            });
        }
    };

    useEffect(() => {
        if(isUpdateExecutedPromptInstanceSuccess){
            dispatch(fullPageLoader(false));
            if(updateExecutedPromptInstanceData.success === true){
                //dispatch(updateAlertMessage({ status: 'success', message: updateExecutedPromptInstanceData?.message }))
                props.setReloadAgentToExecute && props.setReloadAgentToExecute(true);
            }
            else{
                dispatch(updateAlertMessage({ status: 'error', message: updateExecutedPromptInstanceData?.message }))
            }
        }
        if(isUpdateExecutedPromptInstanceError || updateExecutedPromptInstanceError){
            dispatch(fullPageLoader(false));
            dispatch(updateAlertMessage({ status: 'error', message: updateExecutedPromptInstanceData?.message }))
        }
    }, [isUpdateExecutedPromptInstanceSuccess, updateExecutedPromptInstanceData, isUpdateExecutedPromptInstanceError, updateExecutedPromptInstanceError]);

    useEffect(() => {
        if(isCommentSuccess){
            if (commentPromptData?.success === true) {
                dispatch(updateAlertMessage({ status: 'success', message: commentPromptData?.message }))
                dispatch(fullPageLoader(false));
                //props?.onRatingPrompt()
            }
            else {
                dispatch(fullPageLoader(false));
                dispatch(updateAlertMessage({ status: 'error', message: commentPromptData?.message }))
            }
        }
        if(isCommentError || commentPromptError){
            dispatch(fullPageLoader(false));
            dispatch(updateAlertMessage({ status: 'error', message: commentPromptData?.message }))
        }
    }, [isCommentSuccess, isCommentError, commentPromptData, commentPromptError]);

    useEffect(() => {
        if(isRateSuccess){
            dispatch(fullPageLoader(false));
            if (rateResponse?.success === true) {
                dispatch(updateAlertMessage({ status: 'success', message: rateResponse?.message }))
            }
            else {
                dispatch(updateAlertMessage({ status: 'error', message: rateResponse?.message }))
            }
        }
        if(isRateError || rateError){
            dispatch(fullPageLoader(false));
            dispatch(updateAlertMessage({ status: 'error', message: rateResponse?.message }))
        }
    }, [isRateSuccess, isRateError, rateResponse, rateError]);

    const socialShareHandler = (content: string, network: string) => {
        setSocialShareContent(content);
        setSocialShareName(network);
    }

    return (
        <>
        <Card id={props?.cardId}
            titleType={1} title={props?.cardTitle} share={true}
            help={true} Feedback={true} logo={true}
            helpTitle={props?.cardHelpTitle}
            helpContent={props?.cardHelpContent}
            cardHeightClass='h-100'
        >
            <div className='d-flex flex-column align-content-between h-100'>
                {/* ============================== */}
                {/*START SECTION: ICONS*/}
                <div className='d-flex justify-content-end align-items-center mb-3'>
                    <div className={`text-end ${(localAIResponse === '' || !isCompleteExecutionProcess) ? "disabled-item" : ""}`}>
                        <AIResponseActionIcons
                            paramAssetType={localParamAssetType} 
                            assetId= {assetId}
                            assetName= {assetName}
                            assetAuthorName = {assetAuthorName}
                            isShareIconVisible= {isShareIconVisible}
                            questionWithResponse= {`${localQuestionToAsk} \n\n ${localAIResponse.replace(/<\/?[^>]+(>|$)/g, "")}`}
                            responseShareUrl= {`${protocol}//${host}/app/assetIo/${assetURLCODE}&assetType=${localParamAssetType}`}
                            AIResponseData= {localAIResponse}
                            userFirstPromptId= {localUserFirstPromptId}
                            commentModalId= "#sendCommentModalId"
                            ratings={ratings}
                            setRatings={setRatings}
                            setTempRating= {setTempRating}
                            
                        />
                    </div>
                </div>
                {/*END SECTION: ICONS*/}
                {/* ============================ */}
            
                {/* ===================================== */}
                {/*START SECTION: Show API Loading message*/}
                {/* ===================================== */}
                {/* Survey Loading */}
                {isSurveyLoading && 
                    <div className='text-center'>
                        <div className="spinner-border spinner-border-sm text-success" role="status">
                        <span className="visually-hidden">Loading…</span>
                        </div>
                    </div>
                }
                {/* Response Loading with changing processing msgs */}
                {(isPublicRefineResponseLoading || isExecuteAgentLoading) && (
                    <div className='ps-2 py-1 d-flex'>
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText(localProcessingMsgKey, PageTranslation)}} />
                        {TranslationEditFlag && localProcessingMsgKey !== "" && (
                            <EditTranslationIcon translationKey={localProcessingMsgKey} translationType='text' />
                        )}
                        <span className="spinner-border text-success" role="status">
                            <span className="visually-hidden">Loading…</span>
                        </span>
                    </div>
                )}
                {/* =========================================== */}
                {/*END SECTION: Show API Loading message Section*/}
                {/* =========================================== */}

                {/* ========================== */}
                {/*START SECTION: Show response*/}
                {/* ========================== */}
                {/* Survey submit Response */}
                {(isCompleteExecutionProcess && isSurveySuccess && surveyResponseSubmittedData) &&
                    <div className="text-center mt-2">
                        {surveyResponseSubmittedData?.message}
                    </div>
                }
                {/* AI Response */}
                {(localAIResponse !== '' && showAIResponseFlag) 
                    && 
                    (!showAiResponseEditible 
                        ? 
                            <div className='p-2'>
                                <div dangerouslySetInnerHTML={{ __html: localAIResponse }} />
                            </div>
                        :
                        <>
                            {props?.selectedPromptDetails?.SURVEYPROMPT ===1 &&
                                <div className='mb-3'>
                                    <span dangerouslySetInnerHTML={{__html: getTranslatedText('surveyPromptConfirmation.message', PageTranslation)}} />
                                    {TranslationEditFlag && (
                                        <EditTranslationIcon translationKey="surveyPromptConfirmation.message" translationType='text' />
                                    )}
                                </div>
                            }
                            <div>
                                <EditorProvider>
                                    <div className="" >
                                        <Editor value={localAIResponse} onChange={onEditorChange}>
                                            <Toolbar>
                                                <BtnBold />
                                                <BtnItalic />
                                            </Toolbar>
                                        </Editor>
                                    </div>
                                </EditorProvider>  
                            </div>
                            <div className="mt-2 text-center">
                                <div className="d-inline-block">
                                    <TooltipComponent title={props?.selectedPromptDetails?.SURVEYPROMPT === 1 ? getTranslatedText('reviewResponse.tooltip', PageTranslation) : getTranslatedText('refineResult.tooltip', PageTranslation)} >
                                        <button type='button' 
                                            disabled={isPublicRefineResponseLoading} 
                                            onClick={OnTriggedRefineButton} 
                                            className={`btn btn-primary btn-md rounded-pill px-3 mt-3 ${(isPublicRefineResponseLoading) ? 'disabled' : ''}`} 
                                        >
                                            {props?.selectedPromptDetails?.SURVEYPROMPT ===1 
                                            ? 
                                                getTranslatedText('reviewResponse.text', PageTranslation) 
                                            : 
                                                getTranslatedText('refineResult.text', PageTranslation)
                                            }
                                        </button>
                                    </TooltipComponent>
                                    {TranslationEditFlag && (
                                        <>
                                            <EditTranslationIcon translationKey={props?.selectedPromptDetails?.SURVEYPROMPT ===1 ? "reviewResponse.text" : "refineResult.text"} translationType='text' />
                                            <EditTranslationIcon translationKey={props?.selectedPromptDetails?.SURVEYPROMPT ===1 ?"reviewResponse.tooltip" : "refineResult.tooltip"} translationType='tooltip' />
                                        </>
                                    )}
                                        
                                </div>
                                {/* Survey prompt */}
                                {props?.selectedPromptDetails?.SURVEYPROMPT ===1 
                                    ?
                                        <div className="ms-2 d-inline-block">
                                            <TooltipComponent title={getTranslatedText('respondSurvey.tooltip', PageTranslation)}>
                                                <button type='button' 
                                                    disabled={isPublicRefineResponseLoading} 
                                                    onClick={() => {
                                                        submitSurveyResponseAPI({ 
                                                            gptBluePromptId: props?.selectedPromptDetails?.GPTBLUEPROMPTID, 
                                                            surveyResponse: localAIResponse 
                                                        });
                                                    }} 
                                                    className="btn btn-primary btn-md rounded-pill px-3 mt-3 "
                                                >
                                                    {getTranslatedText('finish.text', PageTranslation)}
                                                </button>
                                            </TooltipComponent>
                                            {TranslationEditFlag && (
                                                <>
                                                    <EditTranslationIcon translationKey="finish.text" translationType='text' />
                                                    <EditTranslationIcon translationKey="respondSurvey.tooltip" translationType='tooltip' />
                                                </>
                                            )}  
                                        </div>
                                    :
                                        <div className="ms-2 d-inline-block">
                                            <TooltipComponent title={getTranslatedText('finishExecutionProcess.tooltip', PageTranslation)}>
                                                <button type='button' 
                                                    onClick={OnTriggerFinishButton}
                                                    className="btn btn-primary btn-md rounded-pill px-3 mt-3"
                                                >
                                                    {getTranslatedText('finish.text', PageTranslation)}
                                                </button>
                                            </TooltipComponent>
                                            {TranslationEditFlag && (
                                                <> 
                                                <EditTranslationIcon translationKey="finish.text" translationType='text' />
                                                <EditTranslationIcon translationKey="finishExecutionProcess.tooltip" translationType='tooltip' />
                                                </>
                                            )}
                                        </div>
                                }
                            </div>
                        </>
                    )
                }
                {/* ======================== */}
                {/*END SECTION: Show response*/}
                {/* ======================== */}
                {isShareIconVisible && localAIResponse !== '' && isCompleteExecutionProcess &&
                    <div className={`mt-auto d-flex justify-content-center ${(localAIResponse === '' || !isCompleteExecutionProcess) ? "disabled-item" : ""}`}>
                        <div className="mt-3">
                            <SocialShareIcons
                                size={20}
                                modalId={socialSharePostModalId}
                                shareObject = {props?.paramAssetType || ""}
                                shareObjectId={assetId?.toString() || ""} 
                                contentShareTitle={assetName || ""} 
                                contentShareDesc=  {`${localQuestionToAsk} \n\n ${localAIResponse.replace(/<\/?[^>]+(>|$)/g, "")}`}
                                sharePromptAuthor = {assetAuthorName || ""} 
                                contentShareUrl={`${protocol}//${host}/app/assetIo/${assetURLCODE}&assetType=${localParamAssetType}`}
                                socialShareHandler={socialShareHandler}
                            />
                        </div>
                    </div>
                }
            </div>
        </Card>
        <SendCommentModal 
            id="sendCommentModalId" 
            onSendComment={sendComment}
            commentError={commentError} 
            setCommentError={setCommentError} 
            userComment={userComment} 
            setUserComment={setUserComment}  
        />
        <SocialSharePost 
            id={socialSharePostModalId}
            shareObjectId={props?.selectedAgentDetails?.AGENTID.toString() || ""}
            shareObject='agent'
            socialShareName={socialShareName}
            socialShareContent={socialShareContent}
            socialDescription={props.selectedAgentDetails?.AGENTDESCRIPTION || ""}
            socialImage = {props?.selectedAgentDetails?.agentImage}
            postingOption = 'image/video'
            setSocialShareName={setSocialShareName}
            promptURL={fullURL}
            userPromptId={Number(localUserFirstPromptId)}
        />
        <StarCommentModal 
            id = "starRatingId"
            maxRating = {MAX_RATING}
            rating ={tempRating}
            handleRate={handleSetRating}
            messages = {
            [
                { color: 'var(--bs-gray)', tooltip: getTranslatedText('ratingStar1.tooltip', PageTranslation)  },
                { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar2.tooltip', PageTranslation)  },
                { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar3.tooltip', PageTranslation)  },
                { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar4.tooltip', PageTranslation)  },
                { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar5.tooltip', PageTranslation)  },
                { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar6.tooltip', PageTranslation)  },
                { color: 'var(--bs-secondary)', tooltip: getTranslatedText('ratingStar7.tooltip', PageTranslation)  }
            ]
            }
        />
        </>
    );
};

export default AIResponseGenerator;