import React, { useEffect, useState } from 'react';
import Card from './card/card';
import { useLocation, useNavigate } from 'react-router-dom';
import TooltipComponent from './bootstrap-component/tooltip-component';
import { getTranslatedText } from '../../util/util';
import usePageTranslation from '../../hooks/usePageTranslation';
import EditTranslationIcon from './edit-translation-icon/EditTranslationIcon';
import { useSelector } from 'react-redux';
import DraggableAgent from '../secure/solution-studio/draggable-agent';
import { useGetAgentsMutation } from '../../api-integration/secure/secure';

interface AgentListProps {
    cardId: string;
    cardTitle: string;
    cardHelpTitle: string;
    cardHelpContent: string;
    page: string;
    agentsList?: any;
    availableAgentWorkGroups?: any;
    passedSolutionId?: number;
    isAgentsLoading?: boolean;
    isAgentsSuccess?: boolean;
    setChildAgentCompletionMsg?: (msg: string) => void;
    activeId?: string | null;
    setLocalAgentInstanceId?: (instanceId: number) => void;
    reloadAgentListAPI?: boolean;
    setReloadAgentListAPI?: (prev: boolean) => void;
}

type SortCriteria = "newest_date" | "updated_date" | "alphabetical";

const AgentList: React.FC<AgentListProps> = (props) => {
    const navigate = useNavigate();
    const PageTranslation = usePageTranslation();
    const {  TranslationEditFlag } = useSelector((state: any) => state.commonSlice);
    const location = useLocation();

    // const [filteredData, setFilteredData] = useState<any[]>([]);
    const [sortingData, setSortingData] = useState<any[]>([]);
    const [sortCriteria, setSortCriteria] = useState<SortCriteria>("alphabetical");
    const [isToggled, setIsToggled] = useState<boolean>(true);    
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [localAgentsList, setLocalAgentsList] = useState<any[]>([]);
    const [isAgentWorkGroupSearchBarVisible, setIsShowAgentWorkGroupSearchBarVisible] = useState<boolean>(false);  // State for showing the search bar for 7 seconds
    const [agentWorkGroupSearchQuery, setAgentWorkGroupSearchQuery] = useState<string>('');   // State for controlling agent search field
    const [isAgentWorkGroupActive, setIsAgentWorkGroupActive] = useState<boolean>(false);     // State to determine if the agent Work group is active or inactive
    const [selectedWorkGroup, setSelectedWorkGroup] = useState<string>("");                     // State to store the selected agent Work group
    const [isFilteredWorkGroupAgentsExists, setIsFilteredWorkGroupAgentsExists] = useState<boolean>(false);  // State to determine if the agent is available for the selected agent Work group

    const [getAgentsAPI, {data: getAgentsData, isLoading: isAgentsLoading, isSuccess: isAgentsSuccess, isError: isAgentsError, error: agentsError}] 
    = useGetAgentsMutation();

    // Effect to hide the input after 7 seconds
    useEffect(() => {
        let timer: any;
        if (isAgentWorkGroupSearchBarVisible) {
        timer = setTimeout(() => {
            setIsShowAgentWorkGroupSearchBarVisible(false);
        }, 7000);
        }
        return () => clearTimeout(timer);
    }, [isAgentWorkGroupSearchBarVisible]);

    useEffect(() => {
        if(props?.agentsList) {
            setLocalAgentsList(props?.agentsList);
        }
        else {
            if(props?.reloadAgentListAPI === true) {
                getAgentsAPI({
                    AgentId: 0,
                    fetchHistroyFlag: 0,
                requestMode: props?.page === 'agentIo' ? 'ViewAgent' : 'ManageAgent'
                });
                props?.setReloadAgentListAPI && props?.setReloadAgentListAPI(false);
            }
        }
    }, [props?.agentsList, props?.reloadAgentListAPI]);

    useEffect(() => {
        if(isAgentsSuccess) {
            if(getAgentsData?.AgentDetail?.length > 0) {
                setLocalAgentsList(getAgentsData?.AgentDetail || []);
            }
        }
    },[isAgentsSuccess, isAgentsError, agentsError]);

    // useEffect(() => {
    //     let filteredAgentData = localAgentsList || [];
    //     if (searchQuery !== '') {
    //         filteredAgentData = filteredAgentData.filter(
    //             (item: any) => item.AGENTNAME && item.AGENTNAME.toLowerCase().includes(searchQuery.toLowerCase())
    //         );
    //     }        
    //     setFilteredData(filteredAgentData);
    // }, [searchQuery, localAgentsList]);

    useEffect(() => {
        if(localAgentsList?.length === 0) {
            setSortingData([]);
            return;
        }
        let filteredAgentData = localAgentsList || [];
        // If user has made workgroup active by clicking on W
        if(isAgentWorkGroupActive === true) {
            // If workgroup entered does not exist in the workgroup list (Invalid workgroup entered)
            if(selectedWorkGroup?.length === 0) {
                filteredAgentData = [];
            }
            else {
                filteredAgentData = filteredAgentData.filter((item: any) => item?.AGENTWORKGROUP?.toLowerCase() === selectedWorkGroup?.toLowerCase());
            }
            if(filteredAgentData?.length === 0) {
                setSortingData([]);
                setIsFilteredWorkGroupAgentsExists(false);
                return;
            }
            else {
                setIsFilteredWorkGroupAgentsExists(true);
            }
        }
        // Filtering on basis of search query
        if (searchQuery !== '') {
            filteredAgentData = filteredAgentData.filter(
                (item: any) => item.AGENTNAME && item.AGENTNAME?.toLowerCase().includes(searchQuery?.toLowerCase())
            );
        }
        const sortedData = [...filteredAgentData].sort((a, b) => {
            switch (sortCriteria) {
            case "newest_date":
                if (isToggled) {
                return (
                    new Date(a?.CREATEDTIMESTAMP).getTime() - new Date(b?.CREATEDTIMESTAMP).getTime()
                    );
                } else {
                return (
                    new Date(b?.CREATEDTIMESTAMP).getTime() - new Date(a?.CREATEDTIMESTAMP).getTime()
                );
                }
            case "updated_date":
                if (isToggled) {
                return (
                    new Date(a?.UPDATEDTIMESTAMP).getTime() - new Date(b?.UPDATEDTIMESTAMP).getTime()
                );
                } else {
                return (
                    new Date(b?.UPDATEDTIMESTAMP).getTime() - new Date(a?.UPDATEDTIMESTAMP).getTime()
                );
                }
            case "alphabetical":
                const nameA = a?.AGENTNAME || '';
                const nameB = b?.AGENTNAME || '';
                return isToggled ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
            default:
                return 0;
            }
        });
        setSortingData(sortedData);
    }, [sortCriteria, isToggled, localAgentsList, searchQuery, selectedWorkGroup, isAgentWorkGroupActive]);

    const handleToggle = (param: SortCriteria) => {
        setSortCriteria(param);
        setIsToggled(!isToggled);
    };

    const handleSearchAgentWorkGroup = () => {
        // Hide the agent workgroup search bar
        setIsShowAgentWorkGroupSearchBarVisible(false);
        // Get the agent workgroup list
        let availableWorkGroups;
        if(props?.availableAgentWorkGroups) {
            availableWorkGroups = props?.availableAgentWorkGroups;
        }
        else {
            availableWorkGroups = getAgentsData?.AgentDetail[0]?.availableWorkGroups;
        }
        // To switch off the agent workgroup feature, user needs to clear the agent workgroup search field
        if(agentWorkGroupSearchQuery === '') {
            setIsAgentWorkGroupActive(false);
            setSelectedWorkGroup('');
            return;
        }
        const isWorkGroupPresent = availableWorkGroups?.find((item: any) => item.AGENTWORKGROUP?.toLowerCase() === agentWorkGroupSearchQuery?.toLowerCase());
        setIsAgentWorkGroupActive(true);      
        if(isWorkGroupPresent?.AGENTWORKGROUP?.length > 0) {
            setSelectedWorkGroup(isWorkGroupPresent.AGENTWORKGROUP);
        }
        else {
            setSelectedWorkGroup('');
        }
    }

    return (
        <Card id={props?.cardId}
            titleType={1} title={props?.cardTitle}
            help={true} Feedback={true} logo={true}
            helpTitle={props?.cardHelpTitle}
            helpContent={props?.cardHelpContent}
        >
            {(props?.isAgentsLoading || isAgentsLoading) && (
                <div className="text-center">
                    <div className="spinner-border spinner-border-sm text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
            {(props?.isAgentsSuccess || isAgentsSuccess) && 
                <h6 className="mb-3 d-flex justify-content-evenly">
                    <TooltipComponent title={getTranslatedText('sortByAlphabetical.tooltip', PageTranslation)}>
                    <span className={`cursor-pointer ${sortingData.length === 0 ? 'disabled-item' : ''}`} onClick={() => handleToggle("alphabetical")}>
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('sortByAlphabetical.text', PageTranslation)}} />
                    </span>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                    <div>
                        <EditTranslationIcon translationKey="sortByAlphabetical.text" translationType='text'/>
                        <EditTranslationIcon translationKey="sortByAlphabetical.tooltip" translationType='tooltip' />
                    </div>
                    )}
                    &nbsp; | &nbsp;
                    <TooltipComponent title={getTranslatedText('sortByNewest.tooltip', PageTranslation)}>
                    <span className={`cursor-pointer ${sortingData.length === 0 ? 'disabled-item' : ''}`} onClick={() => handleToggle("newest_date")}>
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('sortByNewest.text', PageTranslation)}} />
                    </span>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                    <div>
                        <EditTranslationIcon translationKey="sortByNewest.text" translationType='text'/>
                        <EditTranslationIcon translationKey="sortByNewest.tooltip" translationType='tooltip' />
                    </div>
                    )}
                    &nbsp; | &nbsp;
                    <TooltipComponent title={getTranslatedText('sortByUpdatedOn.tooltip', PageTranslation)}>
                    <span className={`cursor-pointer ${sortingData.length === 0 ? 'disabled-item' : ''}`} onClick={() => handleToggle("updated_date")}>
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('sortByUpdatedOn.text', PageTranslation)}} />
                    </span>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                    <div>
                        <EditTranslationIcon translationKey="sortByUpdatedOn.text" translationType='text'/>
                        <EditTranslationIcon translationKey="sortByUpdatedOn.tooltip" translationType='tooltip' />
                    </div>
                    )}
                    &nbsp; | &nbsp;
                    <TooltipComponent title={getTranslatedText('filterByWorkGroup.tooltip',PageTranslation)}>
                        <span 
                        className={`cursor-pointer ${isAgentWorkGroupActive ? 'fw-bold' : ''}`} 
                        onClick={() => setIsShowAgentWorkGroupSearchBarVisible(true)} >
                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('w.text', PageTranslation)}} />
                        </span>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                        <div>
                        <EditTranslationIcon translationKey="w.text" translationType='text'/>
                        <EditTranslationIcon translationKey="filterByWorkGroup.tooltip" translationType='tooltip' />
                        </div>
                    )}
                </h6>
            }
            {(props?.isAgentsSuccess || isAgentsSuccess) && (props?.agentsList?.length || localAgentsList?.length) > 0 && (
                <div className="mb-3">
                    {isAgentWorkGroupActive === false && (
                        <div className='text-center'>
                        <span>{getTranslatedText('noWorkGroupSelected.message', PageTranslation)}</span>
                        {TranslationEditFlag && <EditTranslationIcon translationKey="noWorkGroupSelected.message" translationType='text'/>}
                        </div>
                    )}
                    {isAgentWorkGroupActive === true && selectedWorkGroup && (
                        <div className="text-center">
                        <span>{TranslationEditFlag && <EditTranslationIcon translationKey="workGroup.text" translationType='text'/>}{getTranslatedText('workGroup.text',PageTranslation)}: </span><span>{selectedWorkGroup}</span><span> {getTranslatedText('isActive.text',PageTranslation)} {TranslationEditFlag && <EditTranslationIcon translationKey="isActive.text" translationType='text'/>}</span>
                        </div>
                    )}
                    {isAgentWorkGroupSearchBarVisible && (
                    <>
                        <input
                            className="form-control mb-2"
                            type="text"
                            placeholder={getTranslatedText('searchByWorkGroup.placeholder', PageTranslation)}
                            value={agentWorkGroupSearchQuery}
                            onChange={(e) => setAgentWorkGroupSearchQuery(e.target.value)}
                            onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearchAgentWorkGroup();
                            }
                            }}
                            onBlur={handleSearchAgentWorkGroup}
                        />
                        {TranslationEditFlag && <EditTranslationIcon translationKey='searchByWorkGroup.placeholder' translationType='placeholder'/>}
                        </>
                    )}
                    <input
                    className='form-control'
                    type="text"
                    placeholder={getTranslatedText('agentSearch.placheloder', PageTranslation)}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    {TranslationEditFlag && <EditTranslationIcon translationKey='agentSearch.placheloder' translationType='placeholder'/>}
                    {isAgentWorkGroupActive === true && isFilteredWorkGroupAgentsExists === false && (
                        <div className="text-danger text-center">
                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('noAgentForSelectedWorkGroup.message', PageTranslation)}}/>
                            {TranslationEditFlag && <EditTranslationIcon translationKey='noAgentForSelectedWorkGroup.message' translationType='text'/>}
                        </div>
                    )}
                </div>
            )}
            {(props?.isAgentsSuccess || isAgentsSuccess) &&  sortingData && sortingData?.length === 0 && ((isAgentWorkGroupActive === true && isFilteredWorkGroupAgentsExists === true ) || isAgentWorkGroupActive === false) && (
                <>
                <span dangerouslySetInnerHTML={{__html: getTranslatedText('noAgents.message',PageTranslation)}}></span>
                {TranslationEditFlag && <EditTranslationIcon translationKey="noAgents.message" translationType='text'/>}
                </>
            )}
            {(props?.isAgentsSuccess || isAgentsSuccess) && !props?.passedSolutionId && sortingData?.length > 0 && 
            <div className='overflow-auto scrollbar h-35vh'>
                {sortingData?.map((agent: any, index:number) => (
                    <h6 key={index} className='mb-3'>
                        <span 
                        className='cursor-pointer' 
                        onClick={() => {
                            props?.setChildAgentCompletionMsg && props?.setChildAgentCompletionMsg('');
                            if(location.pathname === '/app/agentStudio') {
                                navigate('/app/agentStudio', { state: { passedAgentId: agent?.AGENTID } });
                            }
                            else {
                                navigate(`/app/assetIo/${agent?.AGENTURLCODE}&assetType=agent`);
                                props?.setLocalAgentInstanceId && props?.setLocalAgentInstanceId(0);
                            }
                        }}
                        >
                            {agent?.AGENTNAME}
                        </span>
                    </h6>
                ))}
            </div>
            }
            {(props?.isAgentsSuccess || isAgentsSuccess) && props?.passedSolutionId && (
                <>
                    <div className='overflow-auto scrollbar h-35vh'>
                        {sortingData?.map((agent: any, index:number) => (
                            <DraggableAgent key={index} 
                            agent={agent} 
                            containerId="left" 
                            activeId={props?.activeId}
                            />
                        ))}
                    </div>
                </>
            )}
        </Card>
    );
};

export default AgentList;
